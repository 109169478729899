<template>
    <div>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="auto">
                <h1>
                    {{ $t("account.myAccount") }}
                </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="100%" class="mx-auto" color="primary" outlined>
                    <v-card-text>
                        <loader
                            :isLoading="isLoadingGetInformation"
                            :error="errorGetInformation"
                            errorMessage="account.showError"
                        >
                            <h2 class="text-center mb-4" color="primary">
                                {{ $t("account.myInformation") }}
                            </h2>

                            <v-form
                                ref="informationForm"
                                @submit.prevent="onSubmitInformation"
                            >
                                <v-text-field
                                    v-model="email"
                                    :label="$t('users.user.email')"
                                    prepend-icon="mdi-at"
                                    :rules="[
                                        emailRule.required(email),
                                        emailRule.email(email),
                                        emailRule.errors(
                                            this.informationErrors
                                        ),
                                    ]"
                                    required
                                ></v-text-field>

                                <v-row>
                                    <v-col class="text-center">
                                        <v-btn
                                            color="primary"
                                            type="submit"
                                            :loading="isLoadingInformation"
                                        >
                                            {{ $t("button.save") }}
                                        </v-btn>
                                        <p
                                            v-if="informationError"
                                            class="error--text"
                                        >
                                            {{
                                                $t(
                                                    "account.updateInformationError"
                                                )
                                            }}
                                        </p>
                                        <p
                                            v-if="informationSuccess"
                                            class="success--text"
                                        >
                                            {{
                                                $t(
                                                    "account.updateInformationSuccess"
                                                )
                                            }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </loader>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card height="100%" class="mx-auto" color="primary" outlined>
                    <v-card-text>
                        <h2 class="text-center mb-4" color="primary">
                            {{ $t("account.myPassword") }}
                        </h2>

                        <v-form
                            ref="passwordForm"
                            @submit.prevent="onSubmitPassword"
                        >
                            <v-text-field
                                v-model="oldPassword"
                                :type="showOldPassword ? 'text' : 'password'"
                                :label="$t('account.oldPassword')"
                                prepend-icon="mdi-lock-outline"
                                :append-icon="
                                    showOldPassword
                                        ? 'mdi-eye-outline'
                                        : 'mdi-eye-off-outline'
                                "
                                @click:append="
                                    showOldPassword = !showOldPassword
                                "
                                :rules="passwordRule"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="newPassword"
                                :type="showNewPassword ? 'text' : 'password'"
                                :label="$t('account.newPassword')"
                                prepend-icon="mdi-lock-outline"
                                :append-icon="
                                    showNewPassword
                                        ? 'mdi-eye-outline'
                                        : 'mdi-eye-off-outline'
                                "
                                @click:append="
                                    showNewPassword = !showNewPassword
                                "
                                :rules="passwordRule"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="newPassword2"
                                :type="showNewPassword2 ? 'text' : 'password'"
                                :label="$t('account.newPassword2')"
                                prepend-icon="mdi-lock-outline"
                                :append-icon="
                                    showNewPassword2
                                        ? 'mdi-eye-outline'
                                        : 'mdi-eye-off-outline'
                                "
                                @click:append="
                                    showNewPassword2 = !showNewPassword2
                                "
                                :error-messages="
                                    confirmPasswordRules(showNewPassword2)
                                        ? confirmPasswordRules(
                                              showNewPassword2
                                          )
                                        : null
                                "
                                required
                            ></v-text-field>

                            <v-row>
                                <v-col class="text-center">
                                    <v-btn
                                        color="primary"
                                        type="submit"
                                        :loading="isLoadingPassword"
                                    >
                                        {{ $t("button.save") }}
                                    </v-btn>
                                    <p
                                        v-if="errorPassword403"
                                        class="error--text"
                                    >
                                        {{ $t("account.badPassword") }}
                                    </p>
                                    <p v-if="passwordError" class="error--text">
                                        {{ $t("account.updatePasswordError") }}
                                    </p>
                                    <p
                                        v-if="passwordSuccess"
                                        class="success--text"
                                    >
                                        {{
                                            $t("account.updatePasswordSuccess")
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mx-auto" color="error" outlined>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-btn
                                    color="error"
                                    @click="deleteDialog = true"
                                    :loading="isLoadingDelete"
                                >
                                    {{ $t("account.deleteAccount") }}
                                </v-btn>
                                <p v-if="deleteError" class="error--text">
                                    {{ $t("account.deleteError") }}
                                </p>
                                <v-dialog
                                    v-model="deleteDialog"
                                    max-width="500"
                                >
                                    <v-card>
                                        <v-card-title>
                                            {{
                                                $t("account.deleteDialog.title")
                                            }}
                                        </v-card-title>

                                        <v-card-text
                                            v-if="isLoadingDelete"
                                            class="text-center"
                                        >
                                            <v-progress-circular
                                                :size="50"
                                                color="primary"
                                                indeterminate
                                            ></v-progress-circular>
                                            <br />
                                            {{
                                                $t(
                                                    "account.deleteDialog.isLoading"
                                                )
                                            }}
                                        </v-card-text>
                                        <v-card-text v-else>
                                            {{
                                                $t("account.deleteDialog.text")
                                            }}
                                        </v-card-text>

                                        <v-card-actions v-if="!isLoadingDelete">
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="deleteAccount()"
                                            >
                                                {{ $t("button.yes") }}
                                            </v-btn>
                                            <v-btn
                                                text
                                                @click="deleteDialog = false"
                                            >
                                                {{ $t("button.no") }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    data() {
        return {
            isLoadingGetInformation: false,
            errorGetInformation: false,
            email: "",
            informationErrors: {},
            isLoadingInformation: false,
            informationError: false,
            informationSuccess: false,

            oldPassword: "",
            newPassword: "",
            newPassword2: "",
            showOldPassword: false,
            showNewPassword: false,
            showNewPassword2: false,
            isLoadingPassword: false,
            passwordSuccess: false,
            passwordError: false,
            errorPassword403: false,

            deleteDialog: false,
            isLoadingDelete: false,
            deleteError: false,
        };
    },

    computed: {
        ...mapState({
            passwordRule: (state) => state.utils.passwordRule,
            emailRule: (state) => state.utils.emailRule,
        }),
    },

    watch: {
        email() {
            this.email = this.email.toLowerCase();
            this.informationError = false;
            this.informationSuccess = false;
            this.informationErrors = {};
        },
        oldPassword() {
            this.errorPassword403 = false;
            this.passwordSuccess = false;
            this.passwordError = false;
        },
        newPassword() {
            this.passwordSuccess = false;
            this.passwordError = false;
        },
        newPassword2() {
            this.passwordSuccess = false;
            this.passwordError = false;
        },
    },

    mounted() {
        this.getInformation();
    },

    methods: {
        confirmPasswordRules(){
            if(this.newPassword !== this.newPassword2){
                return this.$t("form.password.unconfirmed");
            }
        },

        getInformation() {
            this.isLoadingGetInformation = true;
            this.errorGetInformation = false;

            this.$store
                .dispatch("auth/getUser")
                .then((data) => {
                    this.email = data.data.user.email;
                    this.isLoadingGetInformation = false;
                })
                .catch(() => {
                    this.isLoadingGetInformation = false;
                    this.errorGetInformation = true;
                });
        },

        onSubmitInformation() {
            if (this.$refs.informationForm.validate()) {
                this.isLoadingInformation = true;
                this.informationError = false;
                this.informationSuccess = false;

                this.$store
                    .dispatch("auth/updateInformation", {
                        email: this.email,
                    })
                    .then(() => {
                        this.getInformation();
                        this.isLoadingInformation = false;
                        this.informationSuccess = true;
                    })
                    .catch((err) => {
                        if (err === undefined || err.response === undefined) {
                            this.informationError = true;
                        } else {
                            this.informationErrors = err.response.data;
                        }
                        this.isLoadingInformation = false;
                        this.$refs.informationForm.validate();
                    });
            }
        },

        onSubmitPassword() {
            if (this.$refs.passwordForm.validate()) {
                this.isLoadingPassword = true;
                this.passwordSuccess = false;
                this.passwordError = false;
                this.errorPassword403 = false;

                this.$store
                    .dispatch("auth/updatePassword", {
                        oldPassword: this.oldPassword,
                        newPassword: this.newPassword,
                    })
                    .then(() => {
                        this.isLoadingPassword = false;
                        this.passwordSuccess = true;
                    })
                    .catch((err) => {
                        if (
                            err === undefined ||
                            err.response === undefined ||
                            err.response.status !== 403
                        ) {
                            this.passwordError = true;
                        } else {
                            this.errorPassword403 = true;
                        }
                        this.isLoadingPassword = false;
                        this.$refs.passwordForm.validate();
                    });
            }
        },

        deleteAccount() {
            this.isLoadingDelete = true;
            this.errorDelete = false;

            this.$store
                .dispatch("auth/deleteAccount")
                .then(() => {
                    this.deleteDialog = false;
                    this.isLoadingDelete = false;
                })
                .catch(() => {
                    this.deleteDialog = false;
                    this.deleteError = true;
                    this.isLoadingDelete = false;
                });
        },
    },
};
</script>
